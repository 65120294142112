import classNames from 'classnames'
import { chunk } from 'lodash'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { portfolioPath } from 'src/paths'
import { PrismicCompanyNode } from 'src/types'
import ArrowLeft from '../assets/arrow-left-circle-light.svg'
import ArrowRight from '../assets/arrow-right-circle-light.svg'
import * as carouselStyles from './carousel-styles.module.scss'
import CompanyLogoCard from './company-logo-card'
import * as styles from './latest-investments.module.scss'
import LinkArrow from './link-arrow'

const LatestInvestments = ({ companies }: { companies: PrismicCompanyNode[] }) => {
  const companyGroups = chunk(companies, 3)
  const showControls = companyGroups.length > 1

  return (
    <div className=" mt-3">
      <Row>
        <Col xs={12} md={9}>
          <div className="h1 mb-4">Featured Investments</div>
        </Col>
        <Col xs={12} md={3}>
          <LinkArrow href={portfolioPath()} light={true} block={true} className="mb-4">
            View all investments
          </LinkArrow>
        </Col>
      </Row>
      <CarouselProvider
        isIntrinsicHeight={true}
        naturalSlideWidth={994}
        naturalSlideHeight={400}
        infinite={true}
        totalSlides={companyGroups.length}
      >
        <Slider>
          {companyGroups.map((group, index) => (
            <Slide index={index} key={index}>
              <Row>
                {group.map((node) => {
                  const uid = node.uid
                  const company = node.data
                  return (
                    <Col key={uid} xs={12} md={4}>
                      <CompanyLogoCard uid={uid} image={company.logo} />
                    </Col>
                  )
                })}
              </Row>
            </Slide>
          ))}
        </Slider>
        {showControls && (
          <>
            <DotGroup className={carouselStyles.carouselDotGroup} />
            <ButtonBack className={classNames(styles.carouselButton, styles.carouselButtonLeft)}>
              <ArrowLeft />
            </ButtonBack>
            <ButtonNext className={classNames(styles.carouselButton, styles.carouselButtonRight)}>
              <ArrowRight />
            </ButtonNext>
          </>
        )}
      </CarouselProvider>
    </div>
  )
}

export default LatestInvestments
