import classNames from 'classnames'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { eventPath, eventsPath } from 'src/paths'
import { PrismicEventNode } from 'src/types'
import { dateLong, eventTimeRange } from 'src/utils/dates'
import LinkArrow from './link-arrow'
import * as styles from './upcoming-events.module.scss'

const UpcomingEventCard = ({ event }: { event: PrismicEventNode }) => {
  const classes = classNames(styles.upcomingEventCard)

  const { title, summary } = event.data
  const heading = title.text
  const date = new Date(event.data.date)
  const description = summary.raw
  const cardPath = eventPath(event.uid)

  return (
    <Link to={cardPath} className={classes}>
      {heading && <h4 className={styles.heading}>{heading}</h4>}
      <div className="mt-auto pt-4">
        {!!event.data.date && (
          <div className={styles.eventTimes}>
            <div className={styles.eventTime}>
              <div className={styles.eventTimeLabel}>Date</div>

              {dateLong(date)}
            </div>
            <div className={styles.eventTime}>
              <div className={styles.eventTimeLabel}>Time</div>
              {eventTimeRange(date, event.data.duration_minutes)}
            </div>
          </div>
        )}

        {description && (
          <div className={styles.description}>
            <RichText render={description} />
          </div>
        )}

        <span className="link-underline">Join Here</span>
      </div>
    </Link>
  )
}

const UpcomingEvents = ({ events }: { events: PrismicEventNode[] }) => {
  return (
    <div>
      <Row>
        <Col xs={12} md={9}>
          <div className="h1 mb-4">Upcoming Events</div>
        </Col>
        <Col xs={12} md={3}>
          <LinkArrow href={eventsPath()} dark={true} block={true} className="mb-4">
            View all BBV events
          </LinkArrow>
        </Col>
      </Row>
      <Row>
        {events.map((node) => (
          <Col key={node.uid} xs={12} md={4} className="mb-4">
            <UpcomingEventCard event={node} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default UpcomingEvents
