import { isFuture } from 'date-fns'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Highlights from 'src/components/highlights'
import LatestInvestments from 'src/components/latest-investments'
import LinkArrow from 'src/components/link-arrow'
import NewsletterSignup from 'src/components/newsletter-signup'
import UpcomingEvents from 'src/components/upcoming-events'
import { BbvPageProps, PrismicHomepageProps } from 'src/types'
import Layout from '../components/layout'
import * as styles from './homepage.module.scss'

export const query = graphql`
  query Homepage {
    prismicHomepage {
      data {
        title {
          text
        }
        tagline {
          text
        }
        description {
          raw
        }
        image {
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        newsletter_title
        newsletter_description {
          raw
        }
        newsletter_url {
          url
        }
        highlights {
          highlight {
            document {
              ...highlight
            }
          }
        }
        description_cta_link {
          document {
            ... on PrismicLink {
              id
              data {
                url
              }
            }
          }
        }
        description_cta
      }
    }
    allPrismicEvent(sort: { fields: data___date, order: ASC }) {
      nodes {
        uid
        data {
          title {
            raw
            text
          }
          date
          summary {
            raw
          }
          rsvp_link {
            url
          }
        }
      }
    }
    allPrismicCompany(
      filter: { data: { feature: { eq: true } } }
      sort: { order: DESC, fields: data___invested_date }
    ) {
      nodes {
        uid
        data {
          logo {
            fixed(width: 310) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
        }
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const Homepage = (props: BbvPageProps<PrismicHomepageProps>) => {
  const { data } = props.data.prismicHomepage
  const highlights = data.highlights
    .map((highlight) => highlight.highlight.document)
    .filter((h) => h)
  const events = props.data.allPrismicEvent.nodes
    .filter((e) => isFuture(new Date(e.data.date)))
    .slice(0, 3)
  const companies = props.data.allPrismicCompany.nodes
  const descriptionCtaLink = data.description_cta_link?.document?.data?.url

  const isSSR = typeof window === 'undefined'

  return (
    <Layout pageData={props.data}>
      <div className="page-container p-0">
        {!!data.image.fluid && (
          <Img fluid={data.image.fluid} className={styles.photo} alt={data.image.alt} />
        )}
        <Container>
          <div className={styles.headerContainer}>
            <div className={styles.homeSection}>
              <div className={styles.headerPanel}>
                <Row>
                  <Col xs={12} md={9}>
                    {data.title.text && <h6 className="h6-orange-heading">{data.title.text}</h6>}
                    {data.tagline.text && <h1 className="mb-1">{data.tagline.text}</h1>}
                    <div className={styles.headerDescription}>
                      <RichText render={data.description.raw} />
                    </div>
                  </Col>
                  <Col xs={12} md={3} className="d-flex align-items-center">
                    {!!data.description_cta && !!descriptionCtaLink && (
                      <LinkArrow href={descriptionCtaLink} block={true} textLarge={true}>
                        {data.description_cta}
                      </LinkArrow>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {highlights.length > 0 && (
            <div className={styles.homeSection}>
              <Highlights highlights={highlights} type="company" />
            </div>
          )}
          {!isSSR && events.length > 0 && <UpcomingEvents events={events} />}
        </Container>
      </div>
      {companies.length > 0 && (
        <div className="page-container dark-container py-4 mt-4">
          <Container>
            <LatestInvestments companies={companies} />
          </Container>
        </div>
      )}
      <NewsletterSignup
        title={data.newsletter_title}
        description={data.newsletter_description.raw}
        url={data.newsletter_url.url}
      />
    </Layout>
  )
}

export default withPreview(Homepage)
